import { createContext, ReactNode, useEffect, useState } from 'react';
import { AddonsT } from '@csi-monorepo/core/domain/addons';
import { AidedSellingEnum } from '@csi-monorepo/core/domain/domain';
import { InsuranceT, PlanT } from '@csi-monorepo/core/domain/insurance';
import { ERROR, LANDING, NEW_QUOTATION, PERSONALINFO, QUOTATION_FULL } from '@src/constants/urls';
import useSponsorTag from '@src/hooks/data/useSponsorTag';
import getAddonsService from '@src/services/Addons/getAddonsService';
import GetAidedSelling from '@src/services/AidedSelling/getAidedSelling';
import { getInsurance } from '@src/services/Insurance/getInsuranceService';
import getIpService from '@src/services/IP/getIpService';
import getSponsorService from '@src/services/Sponsor/GetSponsor';
import useAidedSellingStore from '@src/stores/useAidedSellingStore';
import useAppStore from '@src/stores/useAppStore';
import usePlanStore from '@src/stores/usePlanStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import { GetAgentResponseResultTypeEnum } from '@src/types/aidedSelling';
import { useRouter } from 'next/router';

type InsuranceContextProvider = {
  isLoading: boolean;
};

export const InsuranceContext = createContext<InsuranceContextProvider>({
  isLoading: false,
});

const sponsorStyles = `
  .btn-variant-primary, .btn-color-primary.btn-primary, .btn-primary {
    background-color: var(--primary-color, var(--token-color-primary-40));
    color: var(--font-color, var(--token-color-background-neutral-white));
    &:hover {
      background-color: var(--primary-color, var(--token-color-primary-40));
      opacity: 0.8;
    }

    &:active {
      background-color: var(--primary-color, var(--token-color-primary-60));
    }
  }
`;

const InsuranceProvider = ({ children }: { children: ReactNode }) => {
  const { setInsurance, setAidedSellingError, setFeatureFlagUserId, setIp, setAddons, insurance } =
    useAppStore();
  const { setSponsor, unSetSponsor, sponsor } = useSponsorStore();
  const { resetStore: resetPlanStore, id } = usePlanStore();
  const { setAidedSellingData, resetStore, isAided, isAidedByUrl } = useAidedSellingStore();
  const [userId, setUserId] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const router = useRouter();

  useSponsorTag(sponsor?.code ?? '');

  const isSponsorMainPage =
    router.pathname === LANDING ||
    router.pathname === QUOTATION_FULL ||
    router.pathname === PERSONALINFO ||
    router.pathname === NEW_QUOTATION;

  const getSponsor = async (code: string) => {
    resetStore();
    try {
      const { __typename, ...responseSponsor } = await getSponsorService(code);

      if (__typename !== 'SponsorT') {
        unSetSponsor();
      }
      if (responseSponsor && responseSponsor?.id) setSponsor(responseSponsor);

      return responseSponsor;
    } catch (e: unknown) {
      unSetSponsor();
      console.error((e as Record<string, unknown>)?.message || 'Service error.');
      return undefined;
    }
  };

  const getAidedSelling = async (encryptedCode: string) => {
    resetStore();

    const response = await GetAidedSelling({
      encryptedCode,
    });

    const { data } = response;

    const typename = (data?.getAidedSelling?.__typename as string) ?? undefined;
    const name = (data?.getAidedSelling?.nombre as string) ?? undefined;
    const email = (data?.getAidedSelling?.email as string) ?? undefined;
    const type = (data?.getAidedSelling?.type as AidedSellingEnum) ?? undefined;

    if (typename == GetAgentResponseResultTypeEnum.GetAidedSellingT) {
      unSetSponsor();
      setAidedSellingData({
        isAided: true,
        isAidedByUrl: true,
        rut: encryptedCode,
        name,
        type,
        email,
      });
      return;
    }

    setAidedSellingError(true);
    router.push(ERROR);
  };

  const getIp = async () => {
    const ip: string = await getIpService();
    setIp(ip);
  };

  const getInsuranceAndSponsor = async (sponsorCode: string) => {
    try {
      setLoading(true);
      let insurance: InsuranceT;

      if (sponsorCode !== sponsor?.code) {
        const responseSponsor = await getSponsor(sponsorCode);
        insurance = await getInsurance(responseSponsor?.id, userId);
      } else {
        insurance = await getInsurance(sponsor?.id, userId);
      }

      if (!insurance?.plans?.find((plan: PlanT) => plan?.id === id)) resetPlanStore();

      setInsurance(insurance);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (isSponsorMainPage && router?.isReady) {
      const encryptedCode =
        (router?.query?.negocio as string) || (router?.query?.agente as string) || undefined;
      const sponsorCode = (router?.query?.sponsor as string) || sponsor?.code || undefined;

      if (sponsorCode) {
        getInsuranceAndSponsor(sponsorCode);
      } else {
        unSetSponsor();
        setLoading(false);
      }

      if (!isAided && !isAidedByUrl && encryptedCode && !sponsorCode) {
        getAidedSelling(encryptedCode);
      }
    }
  }, [
    router.isReady,
    router?.query?.negocio,
    router?.query?.agente,
    router?.query?.sponsor,
    router?.query?.userId,
  ]);

  useEffect(() => {
    if (router) {
      for (const [key, value] of Object.entries(router?.query)) {
        if (key == 'negocio' || key == 'agente' || key == 'sponsor' || key == 'userId') return;
        setFeatureFlagUserId({ key, value });
      }
    }
  }, [router?.query]);

  useEffect(() => {
    if (router?.query?.userId && userId !== router?.query?.userId) {
      setUserId(router?.query?.userId as string);
    }
  }, [router?.query?.userId]);

  useEffect(() => {
    getIp();
  }, [router?.pathname]);

  useEffect(() => {
    if (sponsor?.branding?.primary) {
      document.body.style.setProperty('--primary-color', sponsor?.branding?.primary);
      const styleElement = document.createElement('style');
      styleElement.innerHTML = sponsorStyles;

      document.head.appendChild(styleElement);
    }
    if (sponsor?.branding?.fontColor) {
      document.body.style.setProperty('--font-color', sponsor?.branding?.fontColor);
    }
  }, [sponsor]);

  const getAddons = async (input: AddonsT[]) => {
    const addons = await getAddonsService({ addons: input });
    if (addons.__typename === 'AddonsDetailsResultT') setAddons(addons?.addons);
  };

  useEffect(() => {
    if (insurance?.plans) {
      const plans = insurance.plans.filter(({ addons }) => addons !== undefined);
      const addons: AddonsT[] = plans.reduce((acc: AddonsT[], value: PlanT) => {
        return value?.addons ? [...acc, ...value.addons] : acc;
      }, []);

      const addonsByContractId: Record<number, number[]> = {};

      addons.forEach(({ idContract, planIds }) => {
        if (addonsByContractId[idContract]) {
          addonsByContractId[idContract].push(...planIds);
        } else {
          addonsByContractId[idContract] = [...planIds];
        }
      });

      const addonsInput: AddonsT[] = Object.entries(addonsByContractId).map(([key, value]) => {
        return { idContract: Number(key), planIds: value };
      });

      getAddons(addonsInput);
    }
  }, [insurance]);

  return (
    <InsuranceContext.Provider
      value={{
        isLoading: loading,
      }}
    >
      {children}
    </InsuranceContext.Provider>
  );
};

export default InsuranceProvider;
