import { ApolloProvider } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { ChatbotProvider } from '@src/contexts/chatbotContext';
import InsuranceProvider from '@src/contexts/insuranceContext';
import apolloClient from '@src/utils/apollo-client';
import { AppProps } from 'next/app';
import { Poppins } from 'next/font/google';
import localFont from 'next/font/local';
import Head from 'next/head';

import '@src/i18n';
import '@bice_vida/ds-theme/onboarding';

import '@BICE_Vida/components/dist/main.css';
import '@BICE_Vida/tsp-components/dist/index.css';
import '@src/styles/app.scss';

datadogRum.init({
  applicationId: process?.env?.datadogApplicationId ?? '',
  clientToken: process?.env?.datadogClientToken ?? '',
  site: 'datadoghq.com',
  service: process?.env?.datadogService ?? '',
  sessionSampleRate: 100,
  env: process?.env?.STAGE ?? '',
  version: process?.env?.VERSION ?? '',
  trackResources: true,
  trackLongTasks: true,
  sessionReplaySampleRate: 35,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

export const poppins = Poppins({
  weight: ['400', '500', '600', '700', '800'],
  display: 'swap',
  subsets: ['latin'],
  variable: '--poppins-font',
});

const materialIcons = localFont({
  src: [
    {
      path: '../../public/fonts/Material-Round.woff2',
      weight: '400',
      style: 'normal',
    },
  ],
  display: 'swap',
});

function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Seguro Complementario de Salud Individual | BICE VIDA</title>
        <link rel="canonical" href="https://segurocomplementariosalud.bicevida.cl" />
        <meta
          name="description"
          content="Complementa la cobertura de tu Isapre o Fonasa y protégete de los gastos médicos ambulatorios y hospitalarios con nuestro Seguro Complementario de Salud."
        />
        <meta name="keywords" content="seguro complementario de salud" />
        <meta property="og:title" content="Seguro Complementario de Salud Individual | BICE VIDA" />
        <meta
          property="og:description"
          content="Complementa la cobertura de tu Isapre o Fonasa y protégete de los gastos médicos ambulatorios y hospitalarios con nuestro Seguro Complementario de Salud."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://segurocomplementariosalud.bicevida.cl/" />
        <meta property="og:image" content="/images/opengraph/op600.jpg" />
        <meta name="twitter:creator" content="@bice_vida" />
        <meta name="twitter:site" content="@bice_vida" />
        <meta
          name="twitter:title"
          content="Seguro Complementario de Salud Individual | BICE VIDA"
        />
        <meta
          name="twitter:description"
          content="Complementa la cobertura de tu Isapre o Fonasa y protégete de los gastos médicos ambulatorios y hospitalarios con nuestro Seguro Complementario de Salud."
        />
        <meta name="twitter:image" content="/images/opengraph/op600.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      <main className="app">
        <ApolloProvider client={apolloClient}>
          <style jsx global>{`
            :root {
              --poppins-font: ${poppins.style.fontFamily};
              --material-icons: ${materialIcons.style.fontFamily};
            }
          `}</style>
          <InsuranceProvider>
            <ChatbotProvider>
              <Component {...pageProps} />
            </ChatbotProvider>
          </InsuranceProvider>
        </ApolloProvider>
      </main>
    </>
  );
}

export default CustomApp;
