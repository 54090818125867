import { gql, useMutation } from '@apollo/client';

export const SEND_MESSAGE_CHATBOT = gql`
  mutation Mutation($args: SendMessageInput) {
    sendMessage(args: $args) {
      __typename
      ... on SendMessageOutput {
        message
      }
      ... on DBErrorT {
        message
      }
      ... on ServiceErrorT {
        message
      }
    }
  }
`;

export const useSendMessageChatbot = () => {
  const [sendMessageChatbot, { data, error, loading }] = useMutation(SEND_MESSAGE_CHATBOT);

  return {
    sendMessageChatbot,
    error,
    data: data?.sendMessage,
    loading,
  };
};

export default useSendMessageChatbot;
