import AccidentalDisease from './accidentalDisease.json';
import Benefits from './benefits.json';
import Chatbot from './chatbot.json';
import Checkout from './checkout.json';
import Components from './components';
import ContactForm from './contact_form.json';
import DatosPersonales from './datos-personales.json';
import DependantQuoteHelpModal from './dependant_quote_help_modal.json';
import ErrorPages from './error_pages.json';
import FAQs from './faqs';
import IdentityValidationModal from './identity_validation_modal.json';
import IdentityVerification from './identity_verification.json';
import Landing from './landing.json';
import newQuotation from './newQuotation.json';
import PaymentToku from './pago-toku.json';
import PaymentSuccess from './payment_success.json';
import PreQuotation from './pre_quotation.json';
import Promo from './promo.json';
import Quotation from './quotation.json';
import Refund from './refund.json';
import Refunds from './refunds.json';
import Resumen from './resumen.json';
import StepFooter from './step-footer.json';
import Templates from './templates';
import UrlGenerator from './url_generator.json';
import WhyPersonalInformationModal from './why_personal_information_modal.json';

const es = {
  ...Components,
  ...DatosPersonales,
  ...StepFooter,
  ...Checkout,
  ...Refund,
  ...ContactForm,
  ...ErrorPages,
  ...IdentityVerification,
  ...PaymentSuccess,
  ...Landing,
  ...FAQs,
  ...DependantQuoteHelpModal,
  ...WhyPersonalInformationModal,
  ...IdentityValidationModal,
  ...UrlGenerator,
  ...Benefits,
  ...Resumen,
  ...Quotation,
  ...Refunds,
  ...Chatbot,
  ...PreQuotation,
  ...Promo,
  ...PaymentToku,
  ...newQuotation,
  ...AccidentalDisease,
};

export const templates = {
  ...Templates,
};

export default es;
