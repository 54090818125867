import LandingAssists from './landing_assists.json';
import LandingComparator from './landing_comparator.json';
import LandingPlanCards from './landing_plan_cards.json';
import LandingPlanCardsOld from './landing_plan_cards_old.json';
import LandingPlansFeatures from './landing_plans_features.json';
import QuotationPlans from './quotation_plans.json';
import QuotationTemplate from './quotation_template.json';

const templates = {
  ...LandingPlanCards,
  ...LandingPlanCardsOld,
  ...QuotationPlans,
  ...QuotationTemplate,
  ...LandingPlansFeatures,
  ...LandingComparator,
  ...LandingAssists,
};

export default templates;
