import { gql, useQuery } from '@apollo/client';
import { InsuranceT } from '@csi-monorepo/core/domain/insurance';
import apolloClient from '@src/utils/apollo-client';

export const GET_INSURANCE = gql`
  query Query($args: GetInsuranceInputType) {
    getInsurance(args: $args) {
      __typename
      ... on InsuranceT {
        active
        crossSelling {
          description
          image {
            desktop
            mobile
          }
          title
          url
        }
        banner {
          name
          text {
            desktop
            mobile
          }
          linkText
          icon {
            desktop {
              src
              height
              width
              tag
            }
            mobile {
              src
              height
              width
              tag
            }
          }
          logo {
            src
            height
            width
            tag
          }
          modal {
            image
            title
            content
            closeButtonText
          }
          paddingTop
          paddingBottom
        }
        midBanner {
          name
          text {
            desktop
            mobile
          }
          linkText
          icon {
            desktop {
              src
              height
              width
              tag
            }
            mobile {
              src
              height
              width
              tag
            }
          }
          logo {
            src
            height
            width
            tag
          }
          modal {
            image
            title
            content
            closeButtonText
          }
          paddingTop
          paddingBottom
        }
        dps {
          questions {
            title
            description
          }
          termsAndConditions
        }
        faq {
          title
          summary
        }
        idContract
        sendType
        name
        paymentChannel
        plans {
          promo {
            text
            badge
            show
            icon
          }
          badges {
            icon
            text
            show
            color
            background
            border
          }
          footerBadges {
            icon
            text
            show
            color
            background
            border
          }
          promos {
            title
            img
            description
            show
            header
          }
          headerPromo {
            title
            img
            description
            show
            header
          }
          footerPromos {
            title
            img
            description
            show
            header
          }
          summaryCoverages {
            title
            name
            coverages2
            coverages
            promo {
              text
              icon
            }
          }
          prices {
            price
            quantity
            iva
            netAmount
          }
          notCoverages
          name
          maxAmountRefund
          icon
          id
          deductible
          coverages {
            badge {
              color
              icon
              text
            }
            name
            subCoverages {
              annualCapCoverage
              capPerBenefit
              name
              notBonus
              withBonus
            }
          }
          quotation {
            header {
              important {
                items {
                  value
                  wrapped
                  subItems
                }
                subTitle
                title
              }
              date
              intro
              title
            }
            sections {
              descriptions
              list
              postParagraphs
              tables {
                header
                isDPS
                isDependant
                rows {
                  value
                  title
                  rowspan
                  colspan
                }
                title
                subTitle
                withBorder
              }
              title
            }
            articles {
              title
              articles {
                title
                paragraphs
              }
            }
            document
          }
          active
          idCoverage
          cardType
          bmi
          bmiPercentage
          showBmi
          additionalAssist
          additionalCoverages {
            badge {
              color
              icon
              text
            }
            name
            subCoverages {
              name
              notBonus
              withBonus
              capPerBenefit
              annualCapCoverage
              annualCap
              assistants
            }
          }
          extraPlan
          addons {
            idContract
            planIds
          }
          beliv
        }
        quotation
        insurance
        documents {
          description
          name
          size
          title
          url
        }
        planVersus {
          title
          subTitle
          header {
            background
            borderColor
            icon {
              width
              src
              height
            }
            planId
            text {
              desktop
              mobile
            }
            textWhite
          }
          rows {
            planId
            borderColor
            icon
            text
            tooltip {
              text
            }
          }
          footer {
            borderColor
            planId
          }
          disclaimer
        }
        relationships {
          id
          name
          maxEntryAge
          maxMembershipAge
          sons
          sort
          parentIds
          children
          errorMessage
        }
        paymentMethods
      }
      ... on ValidationErrorT {
        errors
      }
      ... on GetInsuranceErrorT {
        message
      }
    }
  }
`;

type InsuranceResponse = {
  getInsurance: InsuranceT;
};

export const useInsurance = (sponsorId: string | null = null, userId: string | null = null) => {
  const { loading, error, data, refetch } = useQuery<InsuranceResponse>(GET_INSURANCE, {
    variables: { args: { sponsorId, userId } },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-first',
  });

  return {
    loading,
    error,
    data: data ? data.getInsurance : undefined,
    refetch,
  };
};

export const getInsurance = async (
  sponsorId: string | null = null,
  userId: string | null = null
): Promise<InsuranceT> => {
  const { data } = await apolloClient.query({
    query: GET_INSURANCE,
    variables: { args: { sponsorId, userId } },
    fetchPolicy: 'no-cache',
  });

  return data?.getInsurance;
};
