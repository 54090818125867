import AuthorizedSharingPersonalData from './authorized_sharing_personal_data.json';
import CardSummary from './card_summary.json';
import DependantsForm from './dependants_form.json';
import HealthDeclarationForm from './health_declaration.json';
import HiringSummary from './hiring_summary.json';
import ModalPlanDetails from './modal_plan_details.json';
import PlanVersus from './plan_versus.json';
import PreloadedModal from './preloaded_modal.json';
import QuotationDocument from './quotation_document.json';

const esComponents = {
  ...CardSummary,
  ...HiringSummary,
  ...DependantsForm,
  ...ModalPlanDetails,
  ...HealthDeclarationForm,
  ...AuthorizedSharingPersonalData,
  ...PreloadedModal,
  ...PlanVersus,
  ...QuotationDocument,
};

export default esComponents;
