import axios from 'axios';

const getIpService = async (): Promise<string> => {
  try {
    const response: Record<string, Record<string, string>> = await axios.get('/api/ip');
    return response?.data?.ip || 'not-detected';
  } catch (e) {
    return 'not-detected';
  }
};

export default getIpService;
