import { AidedSellingEnum } from '@csi-monorepo/core/domain/domain';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export type AidedSellingT = {
  rut: string;
  name: string;
  isAided: boolean;
  isAidedByUrl: boolean;
  type?: AidedSellingEnum;
  email?: string;
  executiveSale?: boolean;
};

type AidedSellingActions = {
  setAidedSellingData: (value: Partial<AidedSellingT>) => void;
  resetStore: () => void;
};

type AidedSellingState = AidedSellingT & AidedSellingActions;

const initialState: AidedSellingT = {
  rut: '',
  name: '',
  isAided: false,
  isAidedByUrl: false,
  executiveSale: undefined,
};

const useAidedSellingStore = create<AidedSellingState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setAidedSellingData: (value: Partial<AidedSellingT>) =>
          set((state) => {
            return { ...state, ...value };
          }),
        resetStore: () => set({ ...initialState }),
      }),
      {
        name: 'csi-aided-selling-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export default useAidedSellingStore;
