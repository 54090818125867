import { gql } from '@apollo/client';
import apolloClient from '@src/utils/apollo-client';

const GET_QUERY = gql`
  query GetAidedSelling($args: GetAidedSellingInputType) {
    getAidedSelling(args: $args) {
      __typename
      ... on GetAidedSellingT {
        nombre
        email
        type
      }
      ... on GetAidedSellingErrorT {
        message
      }
    }
  }
`;

const getAidedSelling = async (values: Record<string, string>) => {
  return await apolloClient.query({
    query: GET_QUERY,
    variables: { args: values },
  });
};

export default getAidedSelling;
