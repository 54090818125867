export const LANDING = '/';
export const CONTACTINFO = '/datos/contacto';
export const DEPENDANTSINFO = '/datos/cargas';
export const PERSONALINFO = '/datos/personales';
export const REFUNDINFO = '/datos/reembolso';
export const SUCCESSPAYMENT = '/solicitud-exitosa';
export const CHECKOUT = '/checkout';
export const ERRORPAYMENT = '/pago-error';
export const HEALTHDECLARATION = '/datos/declaracion-de-salud';
export const VALIDATION_TOPT = '/verificacion-identidad';
export const CHECKOUT_FLOW = '/datos';
export const BENEFITS = '/beneficios';
export const REFUNDS = '/como-reembolsar';
export const ERROR = '/404';
export const PAGO_TOKU = '/pago-toku';

export const QUOTATION_FULL = '/cotiza-en-minutos';
export const NEW_QUOTATION = '/personaliza-tu-cotizacion';

export const VIRTUAL_BRANCH_URL = 'https://portal.bicevida.cl';
export const CIRCLE_BICE_VIDA_HOME =
  'https://circulo.bicevida.cl/?&utm_source=sci&utm_medium=referral&utm_campaign=beneficios-exclusivos';
export const CIRCLE_BICE_VIDA_DENTAL =
  'https://circulo.bicevida.cl/beneficio/dental?&utm_source=sci&utm_medium=referral&utm_campaign=beneficios-exclusivos';
export const CIRCLE_BICE_VIDA_HEALTH =
  'https://circulo.bicevida.cl/beneficio/salud?&utm_source=sci&utm_medium=referral&utm_campaign=beneficios-exclusivos';
export const CIRCLE_BICE_VIDA_WELLNESS =
  'https://circulo.bicevida.cl/beneficio/wellness?&utm_source=sci&utm_medium=referral&utm_campaign=beneficios-exclusivos';
