export enum GetAgentResponseResultTypeEnum {
  GetAgenteT = 'GetAgenteT',
  GetAgenteErrorT = 'GetAgenteErrorT',
  GetRutAgenteT = 'GetRutAgenteT',
  GetRutAgenteErrorT = 'GetRutAgenteErrorT',
  ValidationErrorT = 'ValidationErrorT',
  DBError = 'DBErrorT',
  GetGeneratedAidedUrlT = 'GetGeneratedAidedUrlT',
  GetGeneratedAidedUrlErrorT = 'GetGeneratedAidedUrlErrorT',
  GetAgenteUrlErrorT = 'GetAgenteUrlErrorT',
  GetAidedSellingT = 'GetAidedSellingT',
}

export enum AddLeadAidedSellingResultTypeEnum {
  LeadT = 'LeadT',
  ValidationErrorT = 'ValidationErrorT',
  DBErrorT = 'DBErrorT',
}
