import Question1 from './question1.json';
import Question2 from './question2.json';
import Question3 from './question3.json';
import Question4 from './question4.json';
import Question5 from './question5.json';
import Question6 from './question6.json';
import Question7 from './question7.json';
import Question8 from './question8.json';
import Question9 from './question9.json';
import Question10 from './question10.json';

const faqs = {
  ...Question1,
  ...Question2,
  ...Question3,
  ...Question4,
  ...Question5,
  ...Question6,
  ...Question7,
  ...Question8,
  ...Question9,
  ...Question10,
};

export default faqs;
