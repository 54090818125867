import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatbotContext } from '@src/contexts/chatbotContext';
import { SurveyStateType } from '@src/types/chatbot';

import styles from './SurveyChatbotMessage.module.scss';

const SurveyChatbotMessage = () => {
  const { setSurveyState, surveyState } = useContext(ChatbotContext);
  const { t } = useTranslation();
  const [isNegativeAnswer, setIsNegativeAnswer] = useState<boolean>(false);
  const answerChatMessage = isNegativeAnswer
    ? t('CHATBOT_SURVEY_COMPLETE_NEGATIVE')
    : t('CHATBOT_SURVEY_COMPLETE_THANKS');

  const handleClickSurveyAnswer = (answer: boolean) => {
    if (answer !== undefined) {
      if (!answer) {
        setIsNegativeAnswer(true);
        setSurveyState(SurveyStateType.COMPLETED);
        return;
      }
      setSurveyState(SurveyStateType.INANSWER);
    }
  };

  return (
    <>
      <p>{t('CHATBOT_SURVEY_MESSAGE')}</p>

      {surveyState !== SurveyStateType.COMPLETED ? (
        <div className={styles.chatbot_survey_span__questions}>
          <span
            onClick={() => handleClickSurveyAnswer(true)}
            className={styles.chatbot_survey_span}
          >
            {t('CHATBOT_SURVEY_ANSWER_YES')}
          </span>
          <span
            onClick={() => handleClickSurveyAnswer(false)}
            className={styles.chatbot_survey_span}
          >
            {t('CHATBOT_SURVEY_ANSWER_NO')}
          </span>
        </div>
      ) : (
        <span className={styles.chatbot_survey_span__disable}>{answerChatMessage}</span>
      )}
    </>
  );
};

export default SurveyChatbotMessage;
