import { AddonDetailsT } from '@csi-monorepo/core/domain/addons';
import { InsuranceT, PlanT } from '@csi-monorepo/core/domain/insurance';
import { Document } from '@csi-monorepo/core/domain/insurance';
import { BancosT, BancoT, TipoDeCuentaT, TiposDeCuentasT } from '@src/types/bancos';
import { IsapresT, IsapreT } from '@src/types/isapre';
import { getDocument, getDocuments } from '@src/utils/documents';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export type UserIdPropT = {
  key?: string;
  value?: string | string[];
};

type AppT = {
  isapres: IsapresT;
  bancos: BancosT;
  tiposDeCuentas: TiposDeCuentasT;
  userId: string;
  insurance: InsuranceT;
  addons: AddonDetailsT[];
  existPreexistance: boolean;
  prefilledDataModal: boolean;
  isPrefilledEmail: boolean;
  isAgentNotFound: boolean;
  isPopUpOpen: boolean;
  popUpText?: string;
  featureFlagUserId: UserIdPropT;
  ip: string[];
  showPreQuotation: boolean;
  endedPreQuotation: boolean;
};

type AppActions = {
  setIsapres: (isapres: IsapreT[]) => void;
  setBancos: (bancos: BancoT[]) => void;
  setTiposDeCuentas: (tiposDeCuentas: TipoDeCuentaT[]) => void;
  setUserId: (value: string) => void;
  setInsurance: (insurance: InsuranceT) => void;
  getPlanById: (id?: number) => undefined | PlanT;
  setExistPreexistance: (value: boolean) => void;
  setPrefilledEmail: (value: boolean) => void;
  prefilledDataModalViewed: () => void;
  resetStore: () => void;
  getDocument: (value: string) => Document | undefined;
  getDocuments: (values: string[]) => Document[];
  setAidedSellingError: (value: boolean) => void;
  setPopUp: (value: boolean, text?: string) => void;
  setFeatureFlagUserId: (featureFlagUserId: UserIdPropT) => void;
  setShowPreQuotation: (showPreQuotation: boolean) => void;
  setEndedPreQuotation: (endedPreQuotation: boolean) => void;
  setIp: (value?: string) => void;
  getLastIp: () => string;
  setAddons: (addons: AddonDetailsT[]) => void;
};

type AppState = AppT & AppActions;

const initialState = {
  isapres: [],
  bancos: [],
  tiposDeCuentas: [],
  userId: '',
  relationships: [],
  insurance: {
    plans: [],
    documents: [],
  },
  addons: [],
  existPreexistance: false,
  prefilledDataModal: false,
  isPrefilledEmail: false,
  isAgentNotFound: false,
  isPopUpOpen: false,
  popUpText: '',
  featureFlagUserId: { key: undefined, value: undefined },
  hCaptchaValidation: false,
  ip: [],
  showPreQuotation: false,
  endedPreQuotation: false,
};

const useAppStore = create<AppState>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setIsapres: (isapres) => set({ isapres }),
        setBancos: (bancos) => set({ bancos }),
        setTiposDeCuentas: (tiposDeCuentas) => set({ tiposDeCuentas }),
        setUserId: (value) => set({ userId: value }),
        setInsurance: (insurance) => set({ insurance }),
        getPlanById: (id?: number) => {
          return get().insurance?.plans?.find((item: PlanT) => item.id === id);
        },
        setExistPreexistance: (existPreexistance) => set({ existPreexistance }),
        setPrefilledEmail: (isPrefilledEmail: boolean) => set({ isPrefilledEmail }),
        prefilledDataModalViewed: () => set({ prefilledDataModal: true }),
        resetStore: () => set({ ...initialState }),
        setAidedSellingError: (value) =>
          set((state) => {
            return { ...state, isAgentNotFound: value };
          }),
        setPopUp: (value, text) =>
          set((state) => {
            return { ...state, isPopUpOpen: value, popUpText: text ? text : state.popUpText };
          }),
        getDocuments: (key: string[]): Document[] =>
          getDocuments(key, get()?.insurance?.documents ?? []),
        getDocument: (key: string) => getDocument(key, get()?.insurance?.documents ?? []),
        setFeatureFlagUserId: (featureFlagUserId: UserIdPropT) => set({ featureFlagUserId }),
        setShowPreQuotation: (showPreQuotation: boolean) => set({ showPreQuotation }),
        setEndedPreQuotation: (endedPreQuotation: boolean) => set({ endedPreQuotation }),
        setIp: (value?: string) =>
          set((state) => {
            if (value && !state.ip.includes(value)) state.ip.push(value);
            return { ...state };
          }),
        getLastIp: () => {
          const lastIp: string =
            get().ip && get().ip.length > 0 ? get().ip?.[get().ip.length - 1].split(', ')?.[0] : '';
          return lastIp;
        },
        setAddons: (addons: AddonDetailsT[]) =>
          set((state) => {
            return { ...state, addons };
          }),
      }),
      {
        name: 'csi-app-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export default useAppStore;
