import { gql, useMutation } from '@apollo/client';
import apolloClient from '@src/utils/apollo-client';

export const ADD_SURVEY_CHATBOT = gql`
  mutation AddSurveyChat($args: AddSurveyChatInput) {
    addSurveyChat(args: $args) {
      __typename
      ... on ChatT {
        id
      }
      ... on DBErrorT {
        message
      }
      ... on ServiceErrorT {
        message
      }
      ... on ValidationErrorT {
        errors
      }
    }
  }
`;

export const useAddSurveyChatBotService = () => {
  const [addSurveyChat, { data, error, loading }] = useMutation(ADD_SURVEY_CHATBOT);

  return {
    addSurveyChat,
    error,
    data: data?.addSurveyChat,
    loading,
  };
};

export const addSurveyChatBotService = async (values: Record<string, string>) => {
  const { data } = await apolloClient.mutate({
    mutation: ADD_SURVEY_CHATBOT,
    variables: { args: values },
  });

  return data.addSurveyChat;
};

export default useAddSurveyChatBotService;
