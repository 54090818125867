import { gql, useQuery } from '@apollo/client';
import apolloClient from '@src/utils/apollo-client';

export const GET_FEATURE_FLAG = gql`
  query getFeatureFlag($args: GetFeatureFlagInputType) {
    getFeatureFlag(args: $args) {
      ... on GetFeatureFlagT {
        state
      }
      ... on GetFeatureFlagErrorT {
        message
      }
    }
  }
`;

const getFeatureFlag = async (values: Record<string, string>) => {
  const { data } = await apolloClient.query({
    query: GET_FEATURE_FLAG,
    variables: { args: values },
    fetchPolicy: 'no-cache',
  });
  return data.getFeatureFlag;
};

export const useFeatureFlag = (values: Record<string, string>) => {
  const { loading, error, data } = useQuery(GET_FEATURE_FLAG, {
    variables: { args: values },
    fetchPolicy: 'no-cache',
  });

  return {
    loading,
    error,
    data: data ? data.getFeatureFlag.state : false,
  };
};

export default getFeatureFlag;
