import { gql } from '@apollo/client';
import apolloClient from '@src/utils/apollo-client';

export const CHECK_HCAPTCHA_VALIDATION = gql`
  query Query($args: CheckHCaptchaValidationInputType) {
    checkHCaptchaValidation(args: $args) {
      __typename
      ... on CheckHCaptchaValidationT {
        jwt
      }
      ... on CheckHCaptchaValidationErrorT {
        message
      }
    }
  }
`;

const checkHCaptchaValidationService = async (values: {
  token: string;
  ip: string;
}): Promise<string> => {
  const { data } = await apolloClient.query({
    query: CHECK_HCAPTCHA_VALIDATION,
    variables: { args: values },
  });

  return data?.checkHCaptchaValidation?.jwt || '';
};

export default checkHCaptchaValidationService;
