import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import es, { templates } from './es';

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translations: {
        ...es,
      },
      templates: {
        ...templates,
      },
    },
  },
  fallbackLng: 'es',
  lng: 'es',
  debug: false,

  ns: ['translations', 'templates'],
  defaultNS: 'translations',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
