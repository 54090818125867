import { useEffect } from 'react';

const createScript = (sponsorGTMId: string) => {
  const script = document.createElement('script');
  script.async = true;
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${sponsorGTMId}');
  `;
  document.head.appendChild(script);
};

const SPONSOR_GTM_IDS: { [key: string]: string } = {
  scotiabank: 'GTM-MBHH8XG',
};

const getSponsorGTMId = (sponsorCode: string) => {
  return SPONSOR_GTM_IDS[sponsorCode] || null;
};

function useSponsorTag(sponsorCode: string) {
  useEffect(() => {
    if (process.env.STAGE !== 'prod') {
      return;
    }
    const sponsorGTMId = getSponsorGTMId(sponsorCode);

    if (sponsorGTMId) {
      createScript(sponsorGTMId);
    }
  }, [sponsorCode]);
}

export default useSponsorTag;
