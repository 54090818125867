import { SelectedAddonT } from '@csi-monorepo/core/domain/addons';
import { ContractSendTypeT, PlanT } from '@csi-monorepo/core/domain/insurance';
import { InsurancePlanT } from '@src/types/plan';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type PlanActions = {
  setPlanCostUF: (value: number) => void;
  setBeginDate: (date: string) => void;
  setPlan: (plan: PlanT & ContractSendTypeT, dependantQuantity?: number) => void;
  addAddon: (addon: SelectedAddonT) => void;
  addAddonsRut: (rut: string) => void;
  addonFamilyQuantity: (
    addonId: number,
    familyQuantity: number,
    planFamilyQuantity: number
  ) => void;
  fixFamilyQuantity: (planFamilyQuantity: number) => void;
  removeAddon: (id: number) => void;
  resetAddons: () => void;
  resetStore: () => void;
};

type PlanState = InsurancePlanT & PlanActions;

const initialState = {
  id: undefined,
  name: '',
  planCostUF: undefined,
  beginDate: '',
  maxAmountRefund: undefined,
  deductible: undefined,
  sendType: '',
  idContract: undefined,
  idCoverage: undefined,
  addons: [],
  totalUF: 0,
};

const calculateTotalPrice = (plan: InsurancePlanT): number => {
  let totalUF = plan?.planCostUF || 0;

  plan?.addons?.forEach((addon: SelectedAddonT) => {
    totalUF = ((totalUF as number) + addon?.amount) as number;
  });

  return totalUF;
};

const usePlanStore = create<PlanState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setPlanCostUF: (value) =>
          set((state) => {
            const totalUF = calculateTotalPrice({ ...state, planCostUF: value });
            return { ...state, planCostUF: value, totalUF };
          }),
        setBeginDate: (date) => set({ beginDate: date }),
        setPlan: (plan, dependantQuantity = 0) =>
          set((state) => {
            const { addons, prices, ...restPlan } = plan;

            const newPlan = {
              ...state,
              ...restPlan,
              planCostUF: plan?.prices[dependantQuantity].price || undefined,
              addons: plan?.id !== state?.id ? [] : state?.addons,
            };

            const totalUF = calculateTotalPrice(newPlan);

            return { ...newPlan, totalUF };
          }),
        addAddon: (addon) =>
          set((state) => {
            if (!state?.addons?.find((a) => a.id === addon.id)) {
              let newPlan = { ...state, addons: [addon] };

              if (state.addons) newPlan = { ...state, addons: [...state.addons, addon] };

              const totalUF = calculateTotalPrice(newPlan);

              return { ...newPlan, totalUF };
            }

            return state;
          }),
        addAddonsRut: (rut) =>
          set((state) => {
            const currentAddons = state?.addons;
            const newAddons = currentAddons?.map((addon) => ({ ...addon, ruts: [rut] }));
            const totalUF = calculateTotalPrice({ ...state, addons: newAddons });

            return { ...state, addons: newAddons, totalUF: totalUF as number };
          }),
        addonFamilyQuantity: (addonId, familyQuantity, planFamilyQuantity) =>
          set((state) => {
            const currentAddons = state?.addons;

            if (
              !currentAddons?.find((a) => a.id === addonId) ||
              familyQuantity > 4 ||
              familyQuantity < 1 ||
              familyQuantity > planFamilyQuantity
            )
              return state;

            for (const addon of currentAddons as SelectedAddonT[]) {
              if (addonId === addon?.id) {
                addon.familyQuantity = familyQuantity;
                addon.amount = currentAddons
                  ?.find((a) => a.id === addonId)
                  ?.prices?.find((p) => p.quantity === familyQuantity)?.price as number;
                break;
              }
            }

            const newAddons = currentAddons?.map((a) => {
              if (a.id === addonId) {
                a.familyQuantity = familyQuantity;
                a.amount = currentAddons
                  ?.find((a) => a.id === addonId)
                  ?.prices?.find((p) => p.quantity === familyQuantity)?.price as number;
              }

              return a;
            });

            const totalUF = calculateTotalPrice({ ...state, addons: newAddons });

            return { ...state, addons: newAddons, totalUF: totalUF as number };
          }),
        fixFamilyQuantity: (planFamilyQuantity) =>
          set((state) => {
            const currentAddons = state?.addons;

            const newAddons = currentAddons?.map((a) => {
              if (a.familyQuantity > planFamilyQuantity) {
                a.familyQuantity = planFamilyQuantity;
                a.amount = currentAddons
                  ?.find((a) => a.id === a.id)
                  ?.prices?.find((p) => p.quantity === planFamilyQuantity)?.price as number;
              }

              return a;
            });

            const totalUF = calculateTotalPrice({ ...state, addons: newAddons });

            return { ...state, addons: newAddons, totalUF: totalUF as number };
          }),
        removeAddon: (id) =>
          set((state) => {
            const addons = state?.addons?.filter((a) => a?.id !== id);
            const totalUF = calculateTotalPrice({ ...state, addons });
            return { ...state, addons, totalUF: totalUF as number };
          }),
        resetAddons: () =>
          set((state) => {
            const totalUF = calculateTotalPrice({ ...state, addons: [] });
            return { ...state, addons: [], totalUF };
          }),
        resetStore: () => set({ ...initialState }),
      }),
      {
        name: 'csi-plan-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export default usePlanStore;
