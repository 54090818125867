import { RefObject } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';

export enum ChatbotTypename {
  ChatT = 'ChatT',
  CreateChatError = 'CreateChatError',
  SendMessageError = 'SendMessageError',
  DBError = 'DBError',
  ServiceError = 'ServiceErrorT',
  SendMessageOutput = 'SendMessageOutput',
  ValidationErrorT = 'ValidationErrorT',
}

export enum SurveyStateType {
  INCOMPLETE = 'incomplete',
  ASKED = 'asked',
  INANSWER = 'inAnswer',
  COMPLETED = 'completed',
}

export enum assistantRoleType {
  ASSISTANT = 'assistant',
  USER = 'user',
}

export type ChatbotContextProviderType = {
  isIconOpen: boolean;
  isChatbotOpen: boolean;
  handleChatbotOpen: (value: boolean) => void;
  handleIconOpen: () => void;
  scrollableDivRef?: RefObject<HTMLDivElement>;
  inputRef?: RefObject<HTMLInputElement>;
  messages: messageType[];
  sendMessage: (value: string, isResent?: boolean) => void;
  loading: boolean;
  isChatCreated: boolean;
  idChat: string;
  scrollToBottom: (smooth?: boolean) => void;
  showChatbot: boolean;
  surveyState: SurveyStateType;
  setSurveyState: (state: SurveyStateType) => void;
  loadingSurvey: boolean;
  isSurveySuccess: boolean;
  sendChatbotSurvey: (stars: number, isSolve: boolean | undefined, comments: string) => void;
  setLoadingHCaptcha: (value: boolean) => void;
  onVerifyChatbotField: (token: string) => void;
  fieldValue: string;
  setFieldValue: (value: string) => void;
  captchaRef: RefObject<HCaptcha> | undefined;
  setJWT: (value: string) => void;
  jwt: string;
  isLanding: boolean;
};

export type messageType = {
  role: assistantRoleType;
  content: string | JSX.Element;
  isError?: boolean;
  isChecked?: boolean;
};

export type ComponentsStylingType = {
  p: JSX.Element;
  ul: JSX.Element;
  li: JSX.Element;
  strong: JSX.Element;
  a: JSX.Element;
};
