import { gql } from '@apollo/client';
import { AddonDetailsT, AddonsT } from '@csi-monorepo/core/domain/addons';
import apolloClient from '@src/utils/apollo-client';

export type AddonsDetailsResponseT = {
  __typename: string;
  addons: AddonDetailsT[];
};

export const GET_ADDONS = gql`
  query Addons($args: getAddonsInput) {
    getAddons(args: $args) {
      ... on AddonsDetailsResultT {
        addons {
          _id
          insurance
          name
          idContract
          active
          type
          plans {
            id
            name
            type
            idCoverage
            active
            deductible
            icon
            maxAmountRefund
            icon
            modal {
              title
              description
              alert {
                title
                icon
                description
                document {
                  name
                  title
                  url
                  description
                  size
                }
              }
            }
            quotation {
              header {
                date
                intro
                title
              }
              sections {
                descriptions
                list
                postParagraphs
                tables {
                  header
                  rows {
                    value
                    title
                    rowspan
                    colspan
                  }
                  title
                  subTitle
                  withBorder
                }
                title
              }
            }
            coverages {
              badge {
                color
                icon
                text
              }
              name
              subCoverages {
                annualCapCoverage
                capPerBenefit
                name
                notBonus
                withBonus
              }
            }
            prices {
              quantity
              price
              netAmount
              iva
            }
          }
          documents {
            name
            title
            url
            description
            size
          }
          onlyOwner
        }
      }
      __typename
      ... on ValidationErrorT {
        errors
      }
      ... on DBErrorT {
        message
      }
    }
  }
`;

const getAddonsService = async (values: { addons: AddonsT[] }): Promise<AddonsDetailsResponseT> => {
  const { data } = await apolloClient.query({
    query: GET_ADDONS,
    variables: { args: values },
  });

  return data?.getAddons;
};

export default getAddonsService;
