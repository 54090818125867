import { Trans } from 'react-i18next';
import SurveyChatbotMessage from '@src/components/SurveyChatbotMessage/SurveyChatbotMessage';
import { assistantRoleType, ComponentsStylingType, messageType } from '@src/types/chatbot';

export const addNewMessage = (messages: messageType[], message: messageType): messageType[] => {
  const newMessages = [...messages, message];
  return newMessages;
};

export const getLastMessage = (messages: messageType[]): messageType => {
  const lastIndex = messages.length - 1;
  const lastMessage = messages[lastIndex];

  return lastMessage;
};

export const addNewCheckedMessage = (messages: messageType[], answer: string): messageType[] => {
  let newMessages: messageType[] = [...messages];
  const lastMessage = getLastMessage(messages);
  newMessages.pop();

  newMessages = [
    ...newMessages,
    { role: assistantRoleType.USER, content: lastMessage.content, isError: false, isChecked: true },
    { role: assistantRoleType.ASSISTANT, content: answer },
  ];

  return newMessages;
};

export const setErrorLastMessage = (messages: messageType[]): messageType[] => {
  let newMessages: messageType[] = [...messages];
  const lastMessage = getLastMessage(messages);
  newMessages.pop();

  newMessages = [
    ...newMessages,
    { role: assistantRoleType.USER, content: lastMessage.content, isError: true },
  ];

  return newMessages;
};

export const addSurveyMessage = (messages: messageType[]): messageType[] => {
  const newMessage: messageType = {
    role: assistantRoleType.ASSISTANT,
    content: <SurveyChatbotMessage />,
  };
  return addNewMessage(messages, newMessage);
};

export const renderMessageContent = (
  content: JSX.Element | string,
  componentsStyling: ComponentsStylingType,
  styles: string
): JSX.Element => {
  if (typeof content === 'string') {
    return (
      <Trans className={styles} components={componentsStyling}>
        {content}
      </Trans>
    );
  }

  return content;
};
