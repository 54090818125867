import { createContext, ReactNode, useEffect, useRef, useState } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { initialMessage } from '@src/constants/chatbot';
import { LANDING } from '@src/constants/urls';
import useAddSurveyChatBotService from '@src/services/Chatbot/addSurveyChatService';
import useCreateChatBotService from '@src/services/Chatbot/createChatbotService';
import useSendMessageChatbot from '@src/services/Chatbot/sendMessageChatbotService';
import { useFeatureFlag } from '@src/services/FeatureFlag/getFeatureFlag';
import checkHCaptchaValidationService from '@src/services/HCaptcha/checkHCaptchaValidation';
import useAppStore from '@src/stores/useAppStore';
import useLeadStore from '@src/stores/useLeadStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import {
  assistantRoleType,
  ChatbotContextProviderType,
  ChatbotTypename,
  messageType,
  SurveyStateType,
} from '@src/types/chatbot';
import {
  addNewCheckedMessage,
  addNewMessage,
  addSurveyMessage,
  getLastMessage,
  setErrorLastMessage,
} from '@src/utils/messages';
import { useRouter } from 'next/router';

export const ChatbotContext = createContext<ChatbotContextProviderType>({
  isIconOpen: true,
  isChatbotOpen: false,
  isChatCreated: false,
  idChat: '',
  handleChatbotOpen: (_: boolean) => null,
  handleIconOpen: () => null,
  scrollToBottom: () => null,
  sendMessage: (_: string) => null,
  sendChatbotSurvey: (_: number, __: boolean | undefined, ___: string) => null,
  messages: [],
  loading: false,
  loadingSurvey: false,
  isSurveySuccess: false,
  showChatbot: false,
  surveyState: SurveyStateType.INCOMPLETE,
  setSurveyState: (_: SurveyStateType) => null,
  setLoadingHCaptcha: () => null,
  onVerifyChatbotField: (_: string) => null,
  fieldValue: '',
  setFieldValue: (_: string) => null,
  captchaRef: undefined,
  setJWT: (_: string) => null,
  jwt: '',
  isLanding: true,
});

export const ChatbotProvider = ({ children }: { children: ReactNode }) => {
  const timerSurveyTime = 60000;
  const { featureFlagUserId } = useAppStore();
  const timeoutSurveyRef = useRef<number | null>(null);
  const { isLoading: isLoadingSponsor, sponsor } = useSponsorStore();
  const { id: idLead } = useLeadStore();
  const scrollableDivRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [idChat, setIdChat] = useState<string>('');
  const [isChatCreated, setIsChatCreated] = useState<boolean>(false);
  const [isIconOpen, setIsIconOpen] = useState<boolean>(true);
  const [isChatbotOpen, setIsChatbotOpen] = useState<boolean>(false);
  const [messages, setMessages] = useState<messageType[]>(initialMessage);
  const [isSurveySuccess, setIsSurveySuccess] = useState<boolean>(false);
  const [surveyState, setSurveyState] = useState<SurveyStateType>(SurveyStateType.INCOMPLETE);
  const [loadingHCaptcha, setLoadingHCaptcha] = useState<boolean>(false);
  const [step, setStep] = useState('');
  const [fieldValue, setFieldValue] = useState<string>('');
  const { ip, getLastIp } = useAppStore();
  const [jwt, setJWT] = useState<string>('');
  const [isShowed, setIsShowed] = useState<boolean>(false);
  const [isLanding, setIsLanding] = useState<boolean>(true);
  const [shouldShowChat, setShouldShowChat] = useState<boolean>(false);
  const [lastRequest, setLastRequest] =
    useState<(newToken: string) => (newToken: string) => Promise<unknown> | undefined>();
  const {
    createChatBot,
    data: dataCreate,
    error: errorCreate,
    loading: loadingCreate,
  } = useCreateChatBotService();
  const {
    sendMessageChatbot,
    data: dataMessage,
    loading: loadingMessage,
  } = useSendMessageChatbot();
  const { addSurveyChat, data: dataSurvey, loading: loadingSurvey } = useAddSurveyChatBotService();
  const { data: showChatbot } = useFeatureFlag({
    name: 'chatbot',
    ...(featureFlagUserId.key === 'chatbot' && { userIds: featureFlagUserId.value as string }),
  });
  const { query, pathname, asPath, route } = useRouter();
  const captchaRef = useRef<HCaptcha>(null);

  const handleIconOpen = async () => {
    setIsIconOpen(!isIconOpen);
  };

  const handleChatbotOpen = async (value: boolean) => {
    if (!isChatCreated) {
      if (value === false) {
        setIsChatbotOpen(value);
        return;
      }

      createChatBot({
        variables: {
          args: {
            ...(idLead && { leadId: idLead }),
            ip: getLastIp(),
            step,
          },
        },
      });

      if (errorCreate) return;
    } else {
      if (value === true || !isChatbotOpen) setIsIconOpen(false);
      if (value === undefined || null) return setIsChatbotOpen(!isIconOpen);
      if (value === false && surveyState === SurveyStateType.INCOMPLETE) {
        generateSurveyMessage();
      }
      setIsChatbotOpen(value);
    }
  };

  const sendMessage = (value: string, isResent = false, jwtToken?: string) => {
    if (idChat === '' || undefined) return;

    const stylingMessage = `<p>${value}</p>`;

    if (!isResent) {
      const newMessage = {
        role: assistantRoleType.USER,
        content: stylingMessage,
        isChecked: false,
      };
      setMessages(addNewMessage(messages, newMessage));
      resetTimer();
    }

    sendMessageChatbot({
      variables: {
        args: {
          id: idChat,
          jwt: jwtToken || jwt,
          message: value,
          step,
          ...(idLead && { leadId: idLead }),
          ip: getLastIp(),
        },
      },
    });

    setLastRequest(
      () => async (newToken: string) =>
        await sendMessageChatbot({
          variables: {
            args: {
              id: idChat,
              jwt: newToken,
              message: value,
              step,
              ...(idLead && { leadId: idLead }),
              ip,
            },
          },
        })
    );
  };

  const sendChatbotSurvey = async (stars: number, isSolve?: boolean, comments = '') => {
    setLastRequest(
      () => async (newToken: string) =>
        await addSurveyChat({
          variables: {
            args: {
              id: idChat,
              comment: comments,
              isResolvedQuestion: isSolve,
              rating: stars,
              jwt: newToken,
              step,
            },
          },
        })
    );

    await addSurveyChat({
      variables: {
        args: {
          id: idChat,
          comment: comments,
          isResolvedQuestion: isSolve,
          rating: stars,
          jwt,
          step,
        },
      },
    });
  };

  const resetTimer = () => {
    if (timeoutSurveyRef.current) {
      clearTimeout(timeoutSurveyRef.current);
    }
  };

  const scrollToBottom = (smooth = true) => {
    if (scrollableDivRef.current) {
      const scrollableDiv = scrollableDivRef.current;
      const lastChild = scrollableDiv.lastElementChild as HTMLElement | null;

      if (lastChild) {
        lastChild.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
      }
    }
  };

  const generateSurveyMessage = () => {
    const lastMessage = getLastMessage(messages);
    if (
      surveyState === SurveyStateType.INCOMPLETE &&
      lastMessage.role === 'assistant' &&
      messages.length > 1
    ) {
      resetTimer();
      setSurveyState(SurveyStateType.ASKED);
      setMessages(addSurveyMessage(messages));
    }
  };

  const getNewJWT = async (token: string): Promise<string> => {
    setLoadingHCaptcha(true);

    const tokenJWT: string = await checkHCaptchaValidationService({ token, ip: getLastIp() });

    setLoadingHCaptcha(false);

    return tokenJWT;
  };

  const onVerifyChatbotField = async (token: string) => {
    try {
      const newJWT = await getNewJWT(token);

      setJWT(newJWT);

      if (jwt && lastRequest) lastRequest(newJWT)(newJWT);
      else sendMessageField(newJWT);
    } catch (e: unknown) {
      setLoadingHCaptcha(false);
      setJWT('');
    }
  };

  const sendMessageField = (jwt?: string) => {
    sendMessage(fieldValue, undefined, jwt);
    setFieldValue('');
  };

  useEffect(() => {
    const newStep: string = pathname || asPath || route || '';
    if (newStep !== step) setStep(newStep);
  }, [pathname, asPath, route]);

  useEffect(() => {
    if (dataCreate && !isChatCreated) {
      if (dataCreate?.__typename === ChatbotTypename.ChatT) {
        setIdChat(dataCreate.id);
        setJWT(dataCreate.jwt);
        setIsChatCreated(true);
        setIsIconOpen(false);
        setIsChatbotOpen(true);
      }
    }
  }, [dataCreate]);

  useEffect(() => {
    if (dataMessage && isChatCreated) {
      let newMessages;
      if (dataMessage.__typename === ChatbotTypename.SendMessageOutput) {
        newMessages = addNewCheckedMessage(messages, dataMessage?.message);
      } else if (
        dataMessage.__typename === ChatbotTypename.ServiceError &&
        dataMessage?.message == 'Unauthorized'
      ) {
        captchaRef?.current?.execute();
        return;
      } else {
        newMessages = setErrorLastMessage(messages);
      }
      setMessages(newMessages);
    }
  }, [dataMessage]);

  useEffect(() => {
    const lastMessage = getLastMessage(messages);
    if (
      surveyState === SurveyStateType.INCOMPLETE &&
      lastMessage.role === 'assistant' &&
      messages.length > 1
    ) {
      resetTimer();

      timeoutSurveyRef.current = window.setTimeout(() => {
        setSurveyState(SurveyStateType.ASKED);
        setMessages(addSurveyMessage(messages));
      }, timerSurveyTime);
    }

    scrollToBottom();
    inputRef.current?.focus();

    return () => {
      resetTimer();
    };
  }, [messages]);

  useEffect(() => {
    return () => {
      resetTimer();
    };
  }, []);

  useEffect(() => {
    if (dataSurvey && !loadingSurvey) {
      if (dataSurvey?.__typename === ChatbotTypename.ChatT) {
        setIsSurveySuccess(true);
      } else if (
        dataSurvey?.__typename === ChatbotTypename.ServiceError &&
        dataSurvey?.message == 'Unauthorized'
      ) {
        captchaRef?.current?.execute();
        return;
      }
    }
  }, [dataSurvey]);

  useEffect(() => {
    if (
      (!shouldShowChat && showChatbot && !isLoadingSponsor && sponsor === undefined) ||
      (!isLoadingSponsor && query?.sponsor === undefined && sponsor === undefined)
    ) {
      setTimeout(() => {
        setShouldShowChat(true);
        setIsShowed(true);
      }, 10000);
    }

    if (!showChatbot && shouldShowChat && !isShowed) {
      setShouldShowChat(false);
    }
  }, [showChatbot, isLoadingSponsor, sponsor, query?.sponsor]);

  useEffect(() => {
    setIsLanding(pathname === LANDING);
  }, [pathname]);

  return (
    <ChatbotContext.Provider
      value={{
        isIconOpen,
        isChatbotOpen,
        handleChatbotOpen,
        handleIconOpen,
        scrollableDivRef,
        messages,
        sendMessage,
        isChatCreated,
        idChat,
        scrollToBottom,
        inputRef,
        loading: loadingCreate || loadingMessage || loadingHCaptcha,
        surveyState,
        setSurveyState,
        sendChatbotSurvey,
        loadingSurvey,
        isSurveySuccess,
        setLoadingHCaptcha,
        showChatbot: shouldShowChat,
        onVerifyChatbotField,
        fieldValue,
        setFieldValue,
        captchaRef,
        setJWT,
        jwt,
        isLanding,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  );
};
