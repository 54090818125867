import { Document } from '@csi-monorepo/core/domain/insurance';

export const getDocuments = (key: string[], documents: Document[]): Document[] =>
  documents?.filter((item) => {
    if (key.includes(item.name)) return item;
  });

export const getDocument = (key: string, documents: Document[]): Document | undefined =>
  documents?.find((item) => {
    if (key.includes(item.name)) return item;
  });
