import {
  BankInformationT,
  ContactInformationT,
  DependantsQuotationT,
  DependantsT,
  IdentityValidationStateT,
  IdT,
  IsQuotationT,
  LeadT,
  PersonalInformationT,
  PhoneT,
  PrefilledTokenT,
} from '@csi-monorepo/core/domain/lead';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type LeadActions = {
  setLeadBankInformation: (data: Partial<BankInformationT>) => void;
  setLeadContactInformation: (data: Partial<ContactInformationT & PhoneT>) => void;
  setIdentityValidation: (data: IdentityValidationStateT) => void;
  setLeadPersonal: (value: Partial<PersonalInformationT>) => void;
  setLeadPrefilled: (data: Partial<LeadT>) => void;
  setIsQuotation: (data: boolean) => void;
  setLeadId: (value: IdT) => void;
  setLeadQuotationDependants: (value: boolean) => void;
  resetStore: () => void;
};

export type LeadState = IdT &
  PersonalInformationT &
  ContactInformationT &
  IdentityValidationStateT &
  IsQuotationT &
  PrefilledTokenT &
  DependantsT &
  DependantsQuotationT &
  BankInformationT &
  PhoneT &
  LeadActions;

const initialState = {
  id: '',
  name: '',
  lastName: '',
  motherLastName: '',
  rut: '',
  birthdate: '',
  healthSystem: {
    id: undefined,
    name: '',
  },
  preLoaded: false,
  phone: '',
  email: '',
  emailConfirmation: '',
  region: '',
  province: {
    id: undefined,
    name: '',
  },
  address: '',
  house: '',
  bankName: {
    id: undefined,
    name: '',
  },
  accountType: {
    id: undefined,
    name: '',
  },
  accountNumber: '',
  identityValidation: undefined,
  identityValidationState: false,
  isFamiliar: false,
  isQuotation: false,
  quantity: 0,
  dependants: [],
  withDependants: false,
};

const useLeadStore = create<LeadState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setLeadBankInformation: (data) =>
          set((state) => {
            return { ...state, ...data };
          }),
        setLeadContactInformation: (data) => set({ ...data }),
        setIdentityValidation: (data) =>
          set((state) => {
            return { ...state, ...data };
          }),
        setLeadPersonal: (value: Partial<PersonalInformationT>) => set(value),
        setLeadId: (value: IdT) => set(value),
        setLeadPrefilled: (data: Partial<LeadT>) => set({ ...data }),
        setLeadQuotationDependants: (value: boolean) =>
          set((state) => {
            return { ...state, isFamiliar: value };
          }),
        resetStore: () => set({ ...initialState }),
        setIsQuotation: (value: boolean) => set({ isQuotation: value }),
      }),
      {
        name: 'lead-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export default useLeadStore;
