import { gql } from '@apollo/client';
import { SponsorDetailT } from '@csi-monorepo/core/domain/lead';
import apolloClient from '@src/utils/apollo-client';

export const GET_SPONSOR_QUERY = gql`
  query GetSponsor($args: GetSponsorByCodeInputType) {
    getSponsor(args: $args) {
      __typename
      ... on SponsorT {
        id
        rut
        name
        commission
        code
        primary
        character
        logo {
          src
          header {
            width
            height
            show
          }
          footer {
            width
            height
            show
          }
        }
        bmi
        active
        bmiPercentage
        additionalAssist
        isIntermediary
        footerText
        isAidedSellingActive
        paymentChannel
        paymentAgreement
        preQuotation
        showLogo
        petImage {
          src
          width
          height
        }
        branding {
          primary
          fontColor
        }
      }
      ... on NotFoundErrorT {
        errors
      }
      ... on ValidationErrorT {
        errors
      }
      ... on DBErrorT {
        message
      }
    }
  }
`;

const getSponsorService = async (
  code: string
): Promise<SponsorDetailT & { __typename: string }> => {
  const { data } = await apolloClient.query({
    query: GET_SPONSOR_QUERY,
    variables: { args: { code } },
    fetchPolicy: 'cache-first',
  });

  return data?.getSponsor ?? undefined;
};

export default getSponsorService;
