import { SponsorDetailT, SponsorT } from '@csi-monorepo/core/domain/lead';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

type SponsorActions = {
  setSponsor: (sponsor: SponsorDetailT) => void;
  unSetSponsor: () => void;
};

type SponsorState = SponsorT & SponsorActions;

const initialState: SponsorT = {
  sponsor: undefined,
  isLoading: true,
};

const useSponsorStore = create<SponsorState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setSponsor: (sponsor: SponsorDetailT) =>
          set({
            sponsor,
            isLoading: sponsor?.id ? false : true,
          }),
        unSetSponsor: () =>
          set({
            sponsor: undefined,
            isLoading: false,
          }),
      }),
      {
        name: 'csi-sponsor-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export default useSponsorStore;
