import { gql, useMutation } from '@apollo/client';
import apolloClient from '@src/utils/apollo-client';

export const CREATE_CHATBOT = gql`
  mutation Mutation($args: CreateChatInput) {
    createChat(args: $args) {
      __typename
      ... on ChatT {
        id
        leadId
      }
      ... on DBErrorT {
        message
      }
      ... on ServiceErrorT {
        message
      }
    }
  }
`;

export const useCreateChatBotService = () => {
  const [createChatBot, { data, error, loading }] = useMutation(CREATE_CHATBOT);

  return {
    createChatBot,
    error,
    data: data?.createChat,
    loading,
  };
};

export const createChatBotService = async (values: Record<string, string>) => {
  const { data } = await apolloClient.mutate({
    mutation: CREATE_CHATBOT,
    variables: { args: values },
  });

  return data.createChat;
};

export default useCreateChatBotService;
